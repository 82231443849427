<template>
  <div class="wrapper py-5 bg">
    <div class="container">
      <h1 class="text-white" data-aos="fade-down">{{ $t('message.our_team') }}</h1>
    </div>
    <!-- <hr class="mb-4"> -->
    <div class="container text-white">
      <carousel
        :per-page="5"
        :mouseDrag="true"
        :loop="true"
        :scrollPerPage="false"
        :paginationEnabled="false"
        :navigationEnabled="true"
        :autoplay="true"
        :autoplayTimeout="5000"
        :minSwipeDistance="4"
        :perPageCustom="[[320, 1], [576, 2], [768, 4], [1199, 5]]"
      >
        <slide v-for="member in team" :key="member.name">
          <img alt="member" loading="lazy" class="photo" :src="getImgUrl(member.image)" />
          <div>{{ member.name }}</div>
          <p>{{ member.position }}</p>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      team: [
        {
          name: 'Hermel Balcázar',
          position: 'Director & Founder',
          image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/1.png'
        },
        {
          name: 'Agustín Ruiz Saiz',
          position: 'Associate Madrid / Costa Rica',
          image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/3.png'
        },
        {
          name: 'Elena Zalbide Elorduy',
          position: 'Associate Bilbao',
          image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/10.png'
        },
        {
          name: 'Costel Bozomala',
          position: 'Marketing and Communication',
          image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/11.png'
        },
        {
          name: 'Carolina Salvador Moliner',
          position: 'Associate Castellón',
          image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/12.png'
        },
        {
          name: 'Jose Manuel Cosío',
          position: 'Associate Madrid Juan Bravo',
          image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/15.png'
        },
        {
          name: 'Roberto Ruiz Yécora',
          position: 'Associate La Rioja',
          image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/19.png'
        },
        {
          name: 'Lilit Sargsyan',
          position: 'Human Resources',
          image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/21.png'
        }
      ]
    }
  },
  methods: {
    getImgUrl(pic) {
      return require(pic)
    }
  }
}
</script>

<style scoped lang="scss">
  img.photo {
    width: 160px;
    transition: 0.3s ease;
    &:hover {
      transform: scale(1.03);
    }
  }
  .bg {
    background: #009090;
  }
  hr {
    background: #fff;
    width: 10%;
  }

  .team-row {
    margin-bottom: 1rem;
  }
  
  p{
    font-size: 13px;
    color: #cccccc;
  }
</style>

<style>
.VueCarousel-navigation-button {
  color: #004646 !important;
  top: 40% !important;
}

@media screen and (max-width: 767px) {
  .VueCarousel-navigation-button {
    position: relative !important;
  }

  .VueCarousel-navigation {
    margin-top: 25px;
  }
}

h1.text-white {
    font-weight: 300;
    font-size: 34px;
    text-transform: uppercase;
    margin-bottom: 2rem;
}
</style>
