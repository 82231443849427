<template>
  <div
    class="cookies-wrapper contiene"
    :class="isClosed ? 'cookies-wrapper__close' : ''"
  >
    <div class="container d-flex justify-content-center flex-wrap">
      <div class="sib-text-form-block col-sm-12 col-lg-12">
        <h2>{{ $t('cookie_accept.aicad_site') }}</h2>
        <p>
          {{ $t('convenio.RGPD_youcan') }} <a
            style="cursor:pointer; color: rgb(4, 101, 245);"
            target="_blank"
            href="https://aicad.es/aviso-legal-privacidad/"
          >{{ $t('convenio.RGPD_policy') }}</a>&nbsp;
        </p>
        <p><strong>{{ $t('convenio.RGPD_responsible_catp') }}: {{ $t('convenio.RGPD_responsible_answ') }}</strong></p>
        <p><strong>{{ $t('convenio.RGPD_finalidad') }}</strong>: {{ $t('convenio.RGPD_info') }}</p>
        <p><strong>{{ $t('convenio.RGPD_almacenamiento') }}</strong>: {{ $t('convenio.RGPD_almacenamiento_anws') }}</p>
        <p>
          <strong>{{ $t('convenio.RGPD_right') }}</strong>: {{ $t('convenio.RGPD_right_anws') }} <a
            style="cursor:pointer; color: rgb(4, 101, 245);"
            target="_blank"
            href="https://aicad.es/aviso-legal-privacidad/"
          >{{ $t('convenio.RGPD_limits') }}</a>.
        </p>
      </div>

      <div class="left text-center">
        {{ $t('cookie_accept.descr') }}
        <a
          target="_blank"
          href="https://aicad.es/page/politica-de-cookies"
        >
          {{ $t('cookie_accept.cookies') }}.
        </a>
      </div>
      <div class="right">
        <div>
          <span
            class="btn-accept"
            @click="accept"
          >
            {{ $t('cookie_accept.accept_continue') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            isClosed: (localStorage.getItem('acceptedCookies')) ? true : false
        }
    },
    mounted() {
        if(localStorage.getItem('acceptedCookies')) {
            this.isClosed = (localStorage.getItem('acceptedCookies'));
        }
    },
    methods: {
        accept() {
            localStorage.setItem('acceptedCookies', true);
            this.isClosed = true;
        }
    }
}
</script>
<style lang="scss">
  .contiene {
    box-shadow: #121212 2px 2px 10px 2px;
  }
  .cookies-wrapper {
    text-align: center;
    background: #fdfdfd;
    width: 100%;
    position: fixed;
    z-index: 10;
    transition: all 1s ease-out;
    opacity: 0.90;
    h2 {
      padding: 0;
      color: #2a2a2a;
      display: block;
      position: static;
    }
    h3 {
      padding: 0;
      color: #2a2a2a;
      text-align: center;
      display: block;
      font-weight: 600;
      position: static;
      border-bottom: 2px solid #009090;
      width: 100% !important;
      text-transform: uppercase;
    }
    p {
      color: #414141;
      padding: 0;
    }
    .left {
      color: black;
      text-align: left;
      a {
        color: rgb(4, 101, 245);
        text-decoration: none;
      }
    }
    .btn-accept {
      color: white;
      cursor: pointer;
      border-radius: 3px;
      background: #188600;
      &:hover {
        background: #145c03;
      }
    }
  }
  /* Mobile */
  @media screen and (orientation: portrait) {
    .cookies-wrapper {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: center;
      height: auto !important;
      z-index: 10;
      bottom: 0;
      &__close {
          bottom: -120%;
      }
      h2 {
        padding: 0;
        font-weight: 700;
        line-height: 100%;
        margin: 2px 0 6px;
      }
      h3 {
        padding: 0;
        color: #2a2a2a;
        text-align: center;
        display: block;
        font-weight: 600;
        position: static;
        border-bottom: 2px solid #009090;
        width: 100% !important;
        text-transform: uppercase;
      }
      p {
        line-height: 1.231;
        margin-top: 0;
        margin-bottom: 0rem;
        font-weight: 600;
        padding: 0;
        text-align: left;
      }
      .left {
        a {
          font-weight: 600;
        }
      }
      .btn-accept {
        margin-top: 1%;
        height: 16px;
        font-weight: 800;
        padding: 4px 10px;
        margin-left: 10px;
        margin-bottom: 0;
      }
    }
    @media screen and (max-width: 1024px){
      @media screen and (max-height:1366px){
        .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.3vh !important;
          }
          .btn-accept {
            font-size: 1.2vh !important;
          }
        }
      }
    }
    @media screen and (max-width: 800px){
      @media only screen and (max-height: 1280px) {
        .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.2vh !important;
          }
          .btn-accept {
            font-size: 1.1vh !important;
          }
        }
      }
      @media only screen and (max-height: 1024px) {
        .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.3vh !important;
          }
          .btn-accept {
            font-size: 1.2vh !important;
          }
        }
      }
      @media only screen and (max-height: 960px) {
        .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.2vh !important;
          }
          .btn-accept {
            font-size: 1.2vh !important;
          }
        }
      }
    }
    @media screen and (max-width: 428px){
      @media only screen and (max-height: 893px) {
        .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.2vh !important;
          }
          .btn-accept {
            font-size: 1.1vh !important;
          }
        }
      }
      @media only screen and (max-height: 883px) {
        .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.2vh !important;
          }
          .btn-accept {
            font-size: 1.1vh !important;
          }
        }
      }
      @media only screen and (max-height: 873px) {
        .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.2vh !important;
          }
          .btn-accept {
            font-size: 1.1vh !important;
          }
        }
      }
      @media only screen and (max-height: 851px) {
        .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.2vh !important;
          }
          .left {
            font-size: 1.2vh !important;
          }
          .btn-accept {
            font-size: 1.1vh !important;
          }
        }
      }
      @media only screen and (max-height: 844px) {
        .cookies-wrapper {
          h2 {
            font-size: 1.6vh !important;
          }
          p {
            font-size: 1.2vh !important;
          }
          a {
            font-size: 1.2vh !important;
          }
          .left {
            font-size: 1.1vh !important;
          }
          .btn-accept {
            font-size: 1.1vh !important;
          }
        }
      }
      @media only screen and (max-height: 823px) {
        .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.2vh !important;
          }
          .btn-accept {
            font-size: 1.2vh !important;
          }
        }
      }
      @media only screen and (max-height: 812px) {
        .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.2vh !important;
          }
          .btn-accept {
            font-size: 1.1vh !important;
          }
        }
      }
      @media only screen and (max-height: 736px) {
        .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.4vh !important;
          }
          .btn-accept {
            font-size: 1.3vh !important;
          }
        }
      }
      @media only screen and (max-height: 731px) {
        .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.2vh !important;
          }
          .btn-accept {
            font-size: 1.2vh !important;
          }
        }
      }
      @media only screen and (max-height: 667px) {
        .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.4vh !important;
          }
          .btn-accept {
            font-size: 1.3vh !important;
          }
        }
      }
      @media only screen and (max-height: 640px) {
        .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.2vh !important;
          }
          .btn-accept {
            font-size: 1.2vh !important;
          }
        }
      }
      @media only screen and (max-height: 568px) {
        .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.4vh !important;
          }
          .btn-accept {
            font-size: 1.3vh !important;
          }
        }
      }
    }
  }
  /* pc */
  @media screen and (orientation: landscape) {
    .cookies-wrapper {
      height: auto !important;
    }
    @media screen and (max-width: 7680px) {
      .cookies-wrapper {
        height: 13% !important;
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.3vh !important;
          }
        .btn-accept {
          font-size: 1.2vh !important;
        }
        .container {
          max-width: 9500px;
        }
      }
    }
    @media screen and (max-width: 3840px) {
      .cookies-wrapper {
        height: 13% !important;
        .container {
          max-width: 2200px;
        }
      }
    }
    @media screen and (max-width: 2048px) {
      .cookies-wrapper {
        height: 14% !important;
      }
    }
    @media screen and (max-width: 1920px) {
      .cookies-wrapper {
        height: 15% !important;
      }
    }
    @media screen and (max-width: 1600px) {
      .cookies-wrapper {
          h2,h3 {
            font-size: 1.5vh !important;
          }
          p, a, .left {
            font-size: 1.2vh !important;
          }
        .btn-accept {
          font-size: 1.1vh !important;
        }
      }
    }
  /* landscape */
  @media screen and (orientation: landscape) {
    .cookies-wrapper {
      padding-top: 5px;
      padding-bottom: 5px;
      height: auto !important;
      text-align: center;
      z-index: 10;
      bottom: 0;
      &__close {
          bottom: -120%;
      }
      h2 {
        padding: 0;
        font-size: 8pt;
        font-weight: 700;
        line-height: 100%;
        margin: 2px 0 6px;
      }
      h3 {
        padding: 0;
        color: #2a2a2a;
        text-align: center;
        display: block;
        font-weight: 600;
        position: static;
        border-bottom: 2px solid #009090;
        width: 100% !important;
        text-transform: uppercase;
      }
      p {
        line-height: 1.231;
        margin-top: 0;
        margin-bottom: 0rem;
        font-weight: 600;
        font-size: 6pt;
        padding: 0;
      }
      .left {
        font-size: 6pt;
        a {
          font-weight: 600;
        }
      }
      .btn-accept {
        margin-top: 1%;
        height: 16px;
        font-weight: 800;
        font-size: 6pt;
        padding: 2px 7px;
        margin-left: 10px;
        margin-bottom: 0;
      }
    }
    @media only screen and (max-height: 500px) {
      .cookies-wrapper {
        h2,h3 {
          font-size: 2.8vh !important;
        }
        p, a, .left {
          font-size: 2.4vh !important;
        }
        .btn-accept {
          font-size: 2.3vh !important;
        }
      }
    }
  }
}
</style>