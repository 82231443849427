<template>
  <div class="about" data-view>
    <b-row class="sliderRow">
      <b-col>
        <b-carousel
          id="carousel1"
          fade
          indicators
          controls
          :interval="4000"
          style="text-shadow: 1px 1px 2px #333;"
          background="#ababab"
          img-width="1024"
          img-height="500"
          v-model="slide"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide
            img-src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/slides/banner1.jpg" img-loading="lazy" img-alt="banner-slide"
          >
            <div class="slider-button" @click="openPricing()">Regístrate</div>
          </b-carousel-slide>
          <b-carousel-slide 
            img-src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/slides/banner2.jpg" img-loading="lazy" img-alt="banner-slide" 
          >
            <div class="slider-button" style="position: absolute; left: -12%;top: 0;"  v-scroll-to="'#formulario'">Asóciate</div>
          </b-carousel-slide>
          <b-carousel-slide 
            img-src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/slides/banner3.jpg" img-loading="lazy" img-alt="banner-slide" 
          >
            <div class="slider-button" style="position: absolute; top: 170%;"  v-scroll-to="'#formulario'">Informaciónes</div>
          </b-carousel-slide>
          <b-carousel-slide 
            img-src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/slides/banner4.jpg" img-loading="lazy" img-alt="banner-slide" 
          >
            <div class="slider-button" v-scroll-to="'#formulario'">Contáctanos</div>
          </b-carousel-slide>
        </b-carousel>
      </b-col>
    </b-row>
    <b-row>
      <div class="contenidoFiltros">
        <div class="container">
        <b-row>
          <div class="col-12 col-sm-9 col-md-8 noPadding plusAncho">
            <p style="font-size: 34px; letter-spacing: -1px;"><img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/lupa-executive2.png">{{ $t('message.enucentro_de') }} <span>{{ $t('message.professionals') }}</span></p>
          </div>
          <div class="col-12 col-sm-12 col-md-4 noPadding plusAncho verTodosProgramas">
            <a class="verTodos" href="#">{{ $t('message.see_all_programs') }} <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icono-4.png" class="img-responsive"></a>
          </div>
        </b-row>
          <div class="col-12 col-sm-12 col-md-12 noPadding plusAncho filtros" >
            <div class="twentypercent">
              <div class="rowSelect2">
                <select class="select_arrow2" name="tema" onchange="">
                  <option value="0" disabled="" selected="">{{ $t('message.the_club') }}</option>
                  <option value="4">{{ $t('message.club_1') }}</option>
                  <option value="2">{{ $t('message.club_2') }}</option>
                  <option value="10">{{ $t('message.club_3') }}</option>
                  <option value="12">{{ $t('message.club_4') }}</option>

                </select>
              </div>
            </div>
            <div class="twentypercent">
              <div class="rowSelect2">
                <select class="select_arrow2" name="tipo" onchange="">
                  <option value="0" disabled="" selected="">{{ $t('message.services') }}</option>
                  <option value="/formacion-company/catalogo/">{{ $t('message.service_1') }}</option>
                  <option value="/executive/catalogo/?tipo=10">{{ $t('message.service_7') }}</option>
                  <option value="/executive/catalogo/?tipo=9">{{ $t('message.service_10') }}</option>
                  <option value="/executive/catalogo/?tipo=e">{{ $t('message.service_4') }}</option>
                  <option value="/executive/catalogo/?tipo=10">{{ $t('message.service_5') }}</option>
                  <option value="/executive/catalogo/?tipo=10">{{ $t('message.service_8') }}</option>
                  <option value="/executive/catalogo/?tipo=10">{{ $t('message.service_3') }}</option>
                  <option value="/formacion-company/catalogo/">{{ $t('message.service_2') }}</option>
                  <option value="/executive/catalogo/?tipo=10">{{ $t('message.service_9') }}</option>
                </select>
              </div>
            </div>
            <div class="twentypercent">
              <div class="rowSelect2">
                <select class="select_arrow2" name="lugar" onchange="">
                  <option value="0" disabled selected>{{ $t('message.events') }}</option>
                  <option value="55">A coruña</option>
                  <option value="03">Alicante</option>
                  <option value="12">Barcelona</option>
                  <option value="09">Bilbao</option>
                  <option value="20">Vastellón</option>
                  <option value="20">Córdoba</option>
                  <option value="20">Cuenca</option>
                  <option value="08">Granada</option>
                  <option value="20">La rioja</option>
                  <option value="20">Lugones-asturias</option>
                  <option value="01">Madrid – juan bravo</option>
                  <option value="01">Madrid-leganés</option>
                  <option value="10">Málaga</option>
                  <option value="05">Rioja-navarra</option>
                  <option value="20">Tenerife</option>
                  <option value="20">Vigo. galicia</option>
                  <option value="" disabled>{{ $t('message.events2') }}</option>
                  <option value="03">Alemania - Düsseldorf</option>
                  <option value="03">Costa Rica</option>
                  <option value="03">Ecuador</option>  
                  <option value="03">Guatema</option>
                  <option value="03">México</option>
                  <option value="03">Perú</option>
                  <option value="03">Polonia</option>
                  <option value="03">Rep. Dominicana</option>                  
                  <option value="03">USA - New York</option>
                  <option value="03">USA - Miami</option>
                </select>
              </div>
            </div>
            <div class="twentypercent">
              <div class="rowSelect2">
                <select class="select_arrow2" name="formato" onchange="">
                  <option value="0" disabled="" selected="">{{ $t('message.partners') }}</option>
                  <option value="Interships">{{ $t('message.program_1') }}</option>
                  <option value="Erasmus Interns">{{ $t('message.program_2') }}</option>
                  <option value="Internships">{{ $t('message.program_3') }}</option>
                </select>
              </div>
            </div>
            <div class="twentypercent">
              <div class="rowSelect2">
                <select class="select_arrow2" name="idioma" onchange="">
                  <option value="0" disabled="" selected="">{{ $t('message.activities') }}</option>
                  <option value="E">{{ $t('message.lang_es') }}</option>
                  <option value="I">{{ $t('message.lang_en') }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <b-row>
      <div class="col-xs-12 noPadding plusAncho back-gran">
        <div class="container">
            <div class="col-md-12 openPrograms">
              <p class="width-full text-title-open-programs">
                <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/Innovacion-radical1.png" class="img-responsive"/>
              </p>
              <div class="formacionEmpresas">
                <div>{{ $t('message.soluciones_llave') }} </div>
                <span>{{ $t('message.exclusive_partners') }}</span>
              </div>
              <b-row style="float: left;">
                <div class="col-md-2" style="    float: left;">
                    <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/Software.png" class="img-responsive">
                    <p class="title_desPrograms">SOFTWARE</p>
                    <p class="descPrograms">{{ $t('message.software_content') }}</p>
                </div>
                <div class="col-md-2">
                    <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/Encuentra-Clientes.png" class="img-responsive">
                    <p class="title_desPrograms">{{ $t('message.find_customers') }}</p>
                    <p class="descPrograms" style="white-space: pre-wrap">{{ $t('message.find_customers_content') }} </p>
                </div>
                <div class="col-md-2">
                    <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/Soluciones-para-socios.png" class="img-responsive">
                    <p class="title_desPrograms">{{ $t('message.solutions_for_partners') }}</p>
                    <p class="descPrograms" style="white-space: pre-wrap">{{ $t('message.solutions_for_partners_content') }}</p>
                </div>
                <div class="col-md-2">
                    <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/Tecnologias.png" class="img-responsive">
                    <p class="title_desPrograms">{{ $t('message.technologies') }}</p>
                    <p class="descPrograms" style="white-space: pre-wrap">{{ $t('message.technologies_content') }}</p>
                </div>
                <div class="col-md-2">
                    <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/Escuela-de-negocios.png" class="img-responsive">
                    <p class="title_desPrograms">{{ $t('message.business_school') }}</p>
                    <p class="descPrograms" style="white-space: pre-wrap">{{ $t('message.business_school_content') }}</p>
                </div>
              </b-row>
            </div>
        </div>
      </div>
    </b-row>

    <b-row>
      <div class="col-xs-12 noPadding plusAncho back-gran grey">
        <div class="container" style="    padding-bottom: 20px;">
          <div class="col-md-12 openPrograms">
            <p class="width-full text-title-open-programs">
              <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/Innovacion-radical2.png" class="img-responsive">
            </p>
            <div class="formacionEmpresas">
              <div>{{ $t('message.radical_innovation') }} </div>
              <span>{{ $t('message.fiscal_consultancy') }}</span>
            </div>
            <div class="contain-margin-auto">
              <b-row>
                <div class="col-md-4 contain-back-grey">
                  <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/Encuentros-profesionales.png" class="img-responsive">
                  <p class="title_desPrograms">{{ $t('message.professional_meetings') }}</p>
                  <p class="descPrograms">{{ $t('message.professional_meetings_content') }}</p>
                </div> 
                <div class="col-md-4 contain-back-grey">
                  <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/Reciclaje-y-tendencias.png" class="img-responsive">
                  <p class="title_desPrograms">{{ $t('message.recycle_trends') }}</p>
                  <p class="descPrograms">{{ $t('message.recycle_trends_content') }}</p>
                </div>
                <div class="col-md-4 contain-back-grey">
                  <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/Networking.png" class="img-responsive">
                  <p class="title_desPrograms">NETWORKING</p>
                  <p class="descPrograms">{{ $t('message.networking_content') }}</p>
                </div>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <b-row class="clearfix" style="height: 70px;background: #f3f3f3;margin-top: -35px;"></b-row>
    <b-row style="text-align: left;">
      <div class="contenidoCarrousel transform-line">
        <div class="container">
          <div>
            <p style="letter-spacing: -2px;"><img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icono-15.png"> {{ $t('message.live_experience') }} <span>AICAD</span></p>
            <div class="carrouselVideos wrap">
              <div class="col-xs-12 col-sm-12 col-md-12 videos frame" id="videos" style="overflow: hidden;">
                <ul class="">
                  <li class="active">
                    <div>
                      <silentbox-single src="https://www.youtube.com/watch?v=HHL-lUtKziE" :autoplay="true" description="">
                        <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/videos/video-a1.jpg" class="img-responsive videoImg">
                      </silentbox-single>
                    </div>
                    <div>
                      <silentbox-single src="https://www.youtube.com/watch?v=O35YDV4g2ok" :autoplay="true" description="">
                        <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/videos/video-a2.jpg" class="img-responsive videoImg">
                      </silentbox-single>
                    </div>
                  </li>

                  <li>
                    <div>
                      <silentbox-single src="https://www.youtube.com/watch?v=q4MeAOkDoXc" :autoplay="true" description="">
                        <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/videos/video-a3.jpg" class="img-responsive videoImg" >
                      </silentbox-single>
                    </div>
                  </li>
                  <li>
                    <div>
                      <silentbox-single src="https://www.youtube.com/watch?v=DKRH2JQq55g" :autoplay="true" description="">
                        <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/videos/video-a4.jpg" class="img-responsive videoImg">
                      </silentbox-single>
                    </div>
                    <div>
                      <silentbox-single src="https://www.youtube.com/watch?v=mRNxM1ao1IU" :autoplay="true" description="">
                        <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/videos/video-a5.jpg" class="img-responsive videoImg">
                      </silentbox-single>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
    </b-row>
    <Team />
    <b-row>
      <ContactsForm  />
      <AcceptCookies />
    </b-row>
    <b-row class="clearfix" style="height: 50px;background: #f3f3f3;"></b-row>
  </div>
</template>
<script>
import ContactsForm from '../components/ContactsForm.vue';
import Team from '../components/Team';
import AcceptCookies from "../components/spain/AcceptCookies";

export default {
  name: 'home',
  components: {
    ContactsForm,
    Team,
    AcceptCookies
  },
  metaInfo() {
    return {
      title: this.$t('message.home'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('message.home') + ' - Club de Ejecutivos' },
        { vmid: "og:site_name", property: "og:site_name", content: "Club de Ejecutivos" },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        { vmid: "og:url", property: 'og:url', content: window.location.origin + this.$route.path },
        { vmid: "og:image", property: 'og:image', content: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/pageheads/empresas.jpg' },
        { vmid: "og:description", property: 'og:description', content: 'Description about Club de Ejecutivos' },
         // Google / Schema.org markup:
        { itemprop: 'name', content: 'Home - Club de Ejecutivos' },
        { itemprop: 'description', content: 'Description about Club de Ejecutivos' },
        { itemprop: 'image', content: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/pageheads/empresas.jpg' }
      ]
    }
  },
  data () {
    return {
      slide: 0,
      sliding: null,

      name: '',
      surname1: '',
      surname2: '',
      phone: '',
      email: '',
      campus: '0',
      message: '',
      acceptTerms: '',

      afterFormSend: false,
      submitStatus: '',
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    openPricing() {
      window.open('/spain/pricing', "_blank");
    }
  }
}
</script>

<style scoped lang="scss">
  .about .row {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
  }

  .about .row .col {
    padding-left: 0px;
    padding-right: 0px;
  }

  .img-fluid {
    height: 100%
  }

  .carousel-item {
    max-height: 500px
  }

  .contenidoFiltros {
    width: 100%;
    height: 100%;
    float: left;
    padding: 31px 0;
    padding-bottom: 50px;
  }

  .contenidoFiltros p {
    float: left;
    font-weight: 300;
    font-size: 34px;
    margin: 10px;
    padding: 10px 0;
    color: #232323;
    text-transform: uppercase;
    font-family: 'open sans condensed', Arial, sans-serif;
    text-align: left;
    letter-spacing: -4px;
  }

  .contenidoFiltros p img {
    float: left;
    width: auto;
    margin-right: 10px;
    margin-top: 4px;
  }

  .contenidoFiltros p span {
    font-weight: 700;
    color: #009090;
    margin-top: -12px;
  }

  .noPadding {
    padding-left: 0;
    padding-right: 0;
  }

  .verTodos {
    height: auto;
    float: right;
    padding: 13px;
    border: 1px solid #ffffff;
    background: #009090;
    font-size: 15px;
    color: #ffffff !important;
    margin-bottom: 5px;
    width: 100%;
    text-decoration: none;
    transition: 0.3s ease;
  }

  a.verTodos:hover {
    opacity: 0.8;
    transition: 0.3s ease;
  }

  .verTodos img {
    float: right;
    width: auto;
    margin-left: 10px;
  }

  .contenidoFiltros .col-md-2 {
    padding-left: 0;
    width: 20%;
    padding-right: 8px;
    display: inline-block;
  }

  .rowSelect2 {
      width: 100%;
      float: left;
      margin: 3px 0;
  }

  .rowSelect2 select {
    height: auto;
    float: left;
    padding: 14px;
    border: 1px solid #ffffff;
    width: 100%;
    overflow: hidden;
    background: url("https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/select_filtros.png") no-repeat right #909090;
    font-size: 15px;
    color: #ffffff;
    cursor: pointer;
  }

  .twentypercent {
    width: 20%;
    display: inline-block;
  }

  .back-gran {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    background: #009090;
    padding-top: 2%;
    padding-bottom: 2%;
    transform: skewY(2deg);
    -webkit-transform: skewY(2deg);
}

.col-xs-12 {
    width: 100%;
    float: left;
}

.openPrograms {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    padding-left: 0;
    margin: 0;
    padding-right: 0;
    transform: skewY(-2deg);
    -webkit-transform: skewY(-2deg);
}

.openPrograms .text-title-open-programs {
    font-size: 34px;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'open sans condensed', Arial, sans-serif;
    float: left;
    margin-right: 15px;
}

.openPrograms .col-md-2 {
    display: inline-table;
    padding-right: 2px;
    padding-left: 3px;
    margin-bottom: 40px;    
}


.title_desPrograms {
    font-family: 'Open sans', Arial, sans-serif;
    font-weight: 700!important;
    font-size: 16px!important;
    text-transform: uppercase!important;
    margin: 0px!important;
    padding: 0px!important;
    padding-bottom: 5px!important;
    color: #ffffff;
  }

  .openPrograms .title_desPrograms ~ .descPrograms {
    color: #ffffff;
    font-family: 'Open sans', Arial, sans-serif;
  }

  .openPrograms .descPrograms, .customPrograms .descPrograms {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    width: 100%;
    float: left;
    margin: 0px 0;
    padding: 0;
    text-transform: none;
  }

  .openPrograms .text-title-open-programs span {
    font-weight: 400;
    line-height: 13px;
    margin-bottom: 24px;
  }

  .back-gran.grey {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    background: #4e4e4e;
    top: -3px;
  }

  .contain-margin-auto {
    display: block;
    margin: 0 auto;
  }

  .col-md-4.contain-back-grey {
    display:inline-block;
    margin-bottom: 40px;
  }

  .col-xs-12 {
    position: relative;
  }

  .contenidoFiltros select, .contenidoFormulario select  {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
  }

  .rowSelect2 {
    margin: unset;
  }

  .container {
    height: auto;
  }

  .verTodosProgramas {
    margin-top: auto;
    margin-bottom: auto;
  }

  .formacionEmpresas {
    font-size: 34px;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'open sans condensed', Arial, sans-serif;
    text-align: left;
    letter-spacing: -2px;
    margin: 20px 0px;
  }

  .formacionEmpresas span {
    font-weight: 400;
    line-height: 13px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 767px) {
    .twentypercent {
      width: 100%;
    }

    .textNoticias {
      min-height: unset !important;
    }

    .contenidoFiltros {
      padding: 0px;
    }
  }
  
  @media (max-width: 503px) {
    .formacionEmpresas {
      font-size: 19px;
    }
  }

  @media (min-width: 768px) {
    .openPrograms .col-md-2 {
      -ms-flex: 0 0 20% !important;
      flex: 0 0 20% !important;
      max-width: 20% !important;
    }
  }

  .transform-line {
    padding: 75px 0 80px;
    z-index: -9999;
  }

  .contenidoCarrousel {
    width: 100%;
    float: left;
    background: #f3f3f3;
    z-index: 1;
    padding-bottom: 60px;
    padding-top: 0px;
  }

  .contenidoCarrousel p {
    width: 98%;
    float: left;
    font-weight: 300;
    font-size: 34px;
    margin: 10px;
    padding: 20px 0;
    color: #232323;
    font-family: 'Open sans condensed', Arial, sans-serif;
    text-transform: uppercase;
  }

  .contenidoCarrousel p span {
    font-weight: 700;
    color: #009090;
  }

  .contenidoActualidad {
    width: 100%;
    float: left;
    padding: 10px 0 0;
    margin: 20px 0 30px;
  }

  .twoCols {
    display: inline-block;
    vertical-align: top;
  }

  .titleNoticia a {
    float: left;
    font-weight: 300;
    font-size: 34px;
    margin: 10px;
    border-right: 1px solid #d5d5d5;
    color: #232323;
    font-family: 'open sans condensed', Arial, sans-serif;
  }

  .titleAgenda a {
    width: 100%;
    float: left;
    font-weight: 300;
    font-size: 34px;
    margin: 10px;
    color: #232323;
    font-family: 'open sans condensed', Arial, sans-serif;
    text-align: left;
  }

  .bloqueNoticias {
    width: 100%;
    float: left;
    height: auto;
    margin: 7px 0;
  }

  .textNoticias {
    background: #f3f3f3;
    padding-top: 10px;
    padding-bottom: 10px;
    /* width: 55%; */
    min-height: 217px;
  }

  .imgNoticias, .imgNoticias img {
    display: inline-block;
    text-align: center;
    height: 182px;
  }

  .titleN {
    color: #232323;
    font-size: 0.9rem;
    width: 100%;
    float: left;
    margin: 5px 0 14px;
  }
  
  .descN {
    width: 100%;
    float: left;
    color: #232323;
    font-size: 0.9rem;
    height: auto;
    margin-bottom: 0;
  }

  .fecha {
    width: 80%;
    float: left;
    height: auto;
    border-top: 1px solid #ababab;
    margin-top: 0px;
    position: relative;
  }

  .btnMas {
    width: auto;
    float: right;
    margin-bottom: -10px;
    margin-top: 7px;
    position: absolute;
    bottom: 10px;
    right: 0px;
  }

  .bloqueAgenda {
    /* width: 48%; */
    /* float: left; */
    height: auto;
    /* margin: 26px 10px 10px 0; */
    border: 1px solid #bfbfbf;
    display: inline-block;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 10px;
  }

  .imgAgenda {
    display: inline-block;
    text-align: center;
    background: url(https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/notices/background-image-agenda.jpg) no-repeat center;
    height: 167px;
    background-size: cover;
  }

  .titleFecha {
    width: 72%;
    float: left;
    padding: 62px 0 58px;
    text-align: justify;
    font-size: 25px;
    font-weight: 700;
    color: #ffffff;
    line-height: 30px;
    margin: 0 45px;
  }

  .big {
    font-size: 90px;
    font-weight: 700;
  }

  .smallText {
    font-size: 28px;
    font-weight: 400;
  }

  .textAgenda {
    background: #f3f3f3;
    padding-top: 10px;
    padding-bottom: 37px;
    width: 100%;
    /* min-height: 15rem; */
  }

  .noticias {
    border-right: 1px solid #ababab;
  }

  img.brands {
    width: 70%;
    height: 0%;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 1200px) {
    .bloqueAgenda {
      width: 48%;
    }
  }


  /*  */
  .carrouselVideos {
    width: 100%;
    float: left;
    height: auto;
    position: relative;
  }

  .frame ul {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    display: block;
    white-space: nowrap;
    font-size: 0;
    overflow: auto;
  }

  .frame ul li {
    height: 100%;
    padding: 0;
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  .carouselLogoImg {
    height: 100px;
  }

  .VueCarousel {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  @media screen and (max-width: 767px) {
    .sliderRow {
      display: none;
    }
  }

  .videoImg:hover {
    opacity: 0.6;
    transition: .5s ease;
  }

  .videoImg {
    transition: .5s ease;
  }

  .iconoMas:hover {
    opacity: 0.8;
    transition: 0.3s ease;
  }

  .iconoMas {
    transition: 0.3s ease;
  }

  .carouselLogoImg:hover {
    opacity: 1;
    transform: scale(1.3);
    transition: 0.3s ease;
  }

  .carouselLogoImg {
    transition: 0.3s ease;
    opacity: 0.8;
  }

  .carousel-item {
    max-height: unset;
  }

@media screen and (max-width: 1199px) {
  .carousel-caption #slideButton {
    margin-left: 83%;
    margin-bottom: 5%;
  }
}

@media screen and (min-width: 1200px) {
  .carousel-caption #slideButton {
    margin-bottom: 9%;
    margin-left: 85%;
  }
}

.descA {
  font-size: 0.9rem;
}

.slider-button {
  width: 15%;
  background: linear-gradient(90deg, rgba(51,195,242,1) 0%, rgba(46,0,53,1) 100%);
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 0.5vw;
  cursor: pointer;
  font-size: 1vw;
  transition: 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 4px 6px 15px 0px rgba(0,0,0,0.75);
  }
}
</style>